import {pick} from 'lodash-es';
import React, {useState} from 'react';
import {SSPApi} from '@sdk';
import {setGlobalLocale} from '@shelf/datetime';
import {SEARCH_PARAMETER_DT_STEP_ID} from '@shelf/constants';
import {selfService} from '@shelf/helpers';
import {stringifyQueryParams} from '@shelf/client-helpers';
import {shallowEqual, useSelector} from 'react-redux';
import {useRouter} from 'next/router';
import Script from 'next/script';
import dynamic from 'next/dynamic';
import type {NextParsedUrlQuery} from 'next/dist/server/request-meta';
import type {LanguageCode} from '@shelf/types/lib/l10n';
import type {NextPage} from 'next';
import type {IncomingMessage} from 'http';
import type {TemplateDefinition} from '@shelf/types/lib/api/self-service';
import type {GemTypes, Query, ReduxState, SSPGetRouteTemplateResponse} from '../lib/types';
import type {PageTypeRecord} from '../lib/types';
import RootComponent from '../components/pages/Root';
import Page from '../components/pages/Page';
import Folder from '../components/pages/Folder';
import Search from '../components/pages/Search';
import {
  createDTJourney,
  getPageInfo,
  getPageType,
  getPageTypeString,
  guardAPIRequest,
} from '../lib/helpers/helpers';
import i18nLib from '../lib/i18n';
import {AppContext} from '../lib/context';
import {getDataForRoute} from '../lib/dataForRoutes';
import {getLocalTemplateVersion} from '../lib/getLocalTemplate';
import TemplateDefinitionsListener from '../components/TemplateDefinitionsListener';
import {isBrowser} from '../lib/helpers/browserHelpers';

const ToastrProvider = dynamic(
  () => import('@shelf/ui-kit').then(({ToastrProvider}) => ToastrProvider),
  {
    ssr: false,
    loading: () => <></>,
  }
);

const getJourneyAndStep = async ({
  isSearchEngine,
  DTStep,
  gemId,
  gemType,
  apiKey,
  accountId,
  libraryId,
  lang,
  gemIdFromURL,
  queryParams,
}: {
  isSearchEngine?: boolean;
  DTStep?: string;
  gemId: string;
  gemType?: string;
  apiKey: string;
  accountId: string;
  libraryId: string;
  lang: LanguageCode;
  gemIdFromURL: string;
  queryParams: NextParsedUrlQuery;
}) => {
  let journey, searchEngineCurrentStep;
  const searchQuery = stringifyQueryParams(queryParams);

  if (!isSearchEngine) {
    journey = await createDTJourney({
      accountId,
      libraryId,
      lang,
      gemId,
      gemType,
      apiKey,
      initialStepId: DTStep,
      searchQuery,
    });
  }

  if (isSearchEngine && DTStep) {
    searchEngineCurrentStep = await SSPApi.getDecisionTreeStep({
      accountId: accountId,
      libraryId,
      lang,
      gemId: gemIdFromURL,
      stepId: DTStep,
      apiKey,
      searchQuery,
    });
  }

  return {journey, searchEngineCurrentStep};
};

export const renderMainComponent = (pageType: PageTypeRecord) => {
  switch (true) {
    case pageType.root:
      return <RootComponent />;
    case pageType.page:
    case pageType.pageDT:
      return <Page />;
    case pageType.folder:
      return <Folder />;
    case pageType.search:
      return <Search />;
    default:
      return null;
  }
};

export const stateSelector = (store: ReduxState) => {
  return {
    sidebarMobileVisible: store.sidebarMobileVisible,
    anonUserId: store.anonUserId,
  };
};

const Root: NextPage<{
  query: Query | undefined;
}> = props => {
  const {query} = props;

  const [templateDefinitionState, setTemplateDefinitionState] = useState<
    TemplateDefinition | undefined
  >(query?.templateDefinition);
  const language = query?.appInfo.settings.language || 'en';

  useState(setGlobalLocale(language as Parameters<typeof setGlobalLocale>[0]));

  const {sidebarMobileVisible} = useSelector(stateSelector, shallowEqual);

  const router = useRouter();

  const term = router?.query?.term as string;

  if (!query || !templateDefinitionState) {
    return null;
  }

  const processedQuery = {
    ...query,
  };

  if (templateDefinitionState) {
    processedQuery.templateDefinition = templateDefinitionState;
  }

  const dataForRoute = getDataForRoute({
    query: processedQuery,
  });

  return (
    <>
      <TemplateDefinitionsListener
        currentLang={dataForRoute.lang}
        onTemplateDefinitionsChange={setTemplateDefinitionState}
        currentRoute={dataForRoute.query?.templateDefinition?.routeDefinitions[0]?.type}
      />

      <AppContext.Provider
        //remove anonUserId from context (taken from state) due to rerender issues
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        value={{
          sidebarMobileVisible,
          withoutNavigation: false,
          term,
          ...dataForRoute,
          query: {
            ...dataForRoute.query,
          },
        }}
      >
        <ToastrProvider />
        {renderMainComponent(query.pageType)}
        {templateDefinitionState.jsDefinition && (
          <Script
            id={'globalScript'}
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: templateDefinitionState.jsDefinition,
            }}
          />
        )}
      </AppContext.Provider>
    </>
  );
};

Root.getInitialProps = async function (ctx): Promise<{query: Query | undefined}> {
  /**
   * Server info
   */

  const headers = ctx?.req?.headers;
  const libraryId: string = (headers?.['x-shelf-library-id'] as string) || '';

  if (!libraryId && isBrowser()) {
    window.location.reload();

    // Introducing a delay in order not to display a 404 page
    await new Promise(resolve => setTimeout(resolve, 5000));
  }

  const gemId = headers?.['x-shelf-custom-slug-gem-id'] as string;
  const langHeader = headers?.['x-shelf-custom-slug-gem-lang'] as string;
  const urlReq = ctx?.req?.url ?? '';

  const refererReq = headers?.['x-origin-referer'] as string;
  const host = headers?.host as string;

  const queryUrl = (
    ctx?.req as IncomingMessage & {
      query: Record<string, string>;
    }
  )?.query;
  const url = new URL(urlReq, `https://${host}`).pathname ?? '';
  const apiKey = process.env.SSP_X_API_KEY ?? '';
  /**
   * Additional requests
   */

  // eslint-disable-next-line prefer-const
  let [sspPageInfoResp, templateDefinitions] = await Promise.all([
    getPageInfo({
      libraryId,
      url,
      referer: refererReq,
      gemId,
      lang: langHeader,
      query: ctx.query,
      ctx,
    }),
    guardAPIRequest<SSPGetRouteTemplateResponse, undefined>({
      request: () =>
        SSPApi.getRoutesTemplate({
          libraryId,
          apiKey,
          language: selfService.Slug.getSupportedLanguageFromNormalizedSlug(url),
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          routeType: undefined as any,
        }),
      fallback: undefined,
      logMessage: 'failed to get Route Template',
    }),
  ]);

  if (!sspPageInfoResp || !templateDefinitions) {
    return {query: undefined};
  }

  const lang = sspPageInfoResp?.appConfig?.settings?.language || 'en';

  if (process.env.ENVIRONMENT === 'dev' && process.env.LOCAL_TEMPLATE_PATH) {
    templateDefinitions = getLocalTemplateVersion(lang);
  }

  const {currentGem, appConfig, tagsByCategory, searchResult} = sspPageInfoResp;

  const queryParams = ctx.query;
  const isSearchEngine = !!queryParams?.searchEngine;

  if (currentGem?.type === 'Decision Tree') {
    // Set 'Cache-Control' to 'no-store' to prevent the browser from caching the page.
    // This ensures that the data is fetched from the server every time the page is accessed,
    // even when navigating back to the page using the browser's back button.
    ctx.res?.setHeader('Cache-Control', 'no-store');
  }

  const {journey, searchEngineCurrentStep} = await getJourneyAndStep({
    isSearchEngine,
    DTStep: (ctx.query?.[SEARCH_PARAMETER_DT_STEP_ID] ?? '') as string,
    apiKey,
    accountId: appConfig?.accountId,
    libraryId,
    lang,
    gemId: currentGem?._id ?? '',
    gemType: currentGem?.type ?? '',
    gemIdFromURL: (queryParams?.currentGemId ?? '') as string,
    queryParams,
  });

  const articlesGems = currentGem?.gems;
  const folders = currentGem?.folders;
  const folder = folders?.find(folder => folder._id === currentGem?._id);

  const pageType = getPageType({
    url,
    currentGemType: currentGem?.type as GemTypes,
  });

  const pageTypeString = getPageTypeString(pageType);

  const templateDefinition = {
    ...templateDefinitions,
    routeDefinitions: templateDefinitions?.routeDefinitions.filter(
      ({type}) => pageTypeString === type
    ),
  };

  await i18nLib.changeLanguage(lang);

  return {
    query: {
      pageType,
      pageTypeString,
      queryUrl,
      appInfo: sspPageInfoResp?.appConfig,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      folders,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      gems: articlesGems,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      currentGem,
      folder,
      urlReq,
      refererReq,
      url,
      templateDefinition: templateDefinition as any,
      tagsByCategory,
      searchResult,
      searchQuery: ctx.query,
      stringifiedCategory: stringifyQueryParams(pick(ctx.query, 'category')),
      ...(journey && {
        currentStep: journey.initialStep,
        journeyId: journey.journeyId,
        firstStepId: journey.firstStepId,
        isOpenDTLinkInNewTabEnabled: journey.isOpenDTLinkInNewTabEnabled,
      }),
      ...(searchEngineCurrentStep && {
        currentStep: searchEngineCurrentStep.step,
        isOpenDTLinkInNewTabEnabled: false,
      }),
    },
  };
};

export default Root;
